import React, { useCallback, useContext, useEffect, useState } from 'react'
import ListPagination from "components/ListPagination";
import { Row, Spinner } from "react-bootstrap";
import ImageGallery from "components/ImageGallery";
import CaseApi from "api/case-api";
import { CaseImagesContext } from "pages/cases/view/case-images/CaseImages";


const caseApi = new CaseApi()

const AllImages = () => {
    const [totalPages, setTotalPages] = useState(0);
    const [gallery, setGallery] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [imageSequenceNumber, setImageSequenceNumber] = useState<string>();
    const {
        captureDate,
        caseInfo,
        locationInfo,
        currentPage,
        setCurrentPage,
        pageSize,
        setPageSize,
        onGoToImage,
        imageSequenceNumberToShowInPopup
    } = useContext(CaseImagesContext)

    // initialize things
    useEffect(() => {
        if (imageSequenceNumberToShowInPopup && imageSequenceNumberToShowInPopup > -1 ) {
            // Since we are in the go-to-img process we will not initialize current 
            // page and page size
        } else {
            setCurrentPage(1)
            setPageSize(240)
        }
    }, [])

    // Fetch all images
    useEffect(() => {
        const getImages = async () => {
            try {
                setIsLoading(true);
                const offset = (currentPage - 1) * pageSize;
                const {images, count} = await caseApi.getLocationImages(
                    caseInfo.id, locationInfo.id, {
                        capture_date: captureDate,
                        limit: pageSize,
                        offset: offset,
                        image_sequence: imageSequenceNumber
                    });
                setGallery([...images]);
                setTotalPages(Math.ceil(count / pageSize));
            } catch (e) {
                console.error(e)
            } finally {
                setIsLoading(false);
            }
        }
        getImages();
    }, [currentPage, pageSize, captureDate, caseInfo.id, locationInfo.id, imageSequenceNumber]);

    const onSetPageSize = useCallback((size: number) => {
        setPageSize(size);
    }, []);

    const onChangePage = useCallback((page: number) => {
        setCurrentPage(page);
    }, []);


    return (
        <React.Fragment>

            <Row className="filterable-content position-relative">
                {isLoading &&
                    <div className="d-flex justify-content-center">
                        <Spinner className="text-primary m-2"/>
                    </div>
                }
                {
                    !isLoading && gallery.length === 0 &&
                    <span className="text-muted">No images found</span>
                }
                <ImageGallery
                    name="All"
                    images={gallery}/>
            </Row>

            <ListPagination
                className="pagination-bottom"
                pageSize={pageSize}
                currentPage={currentPage}
                totalPages={totalPages}
                onSetPageSize={onSetPageSize}
                onChangePage={onChangePage}
                onGoToImage={onGoToImage}
            />
        </React.Fragment>
    );
}

export default React.memo(AllImages);