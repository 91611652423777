import { BackendApi } from "./backend-api";
import { HDMediaRequest } from "../helpers/interfaces";

export default class CameraMediaApi {
    private backendApi: BackendApi;

    constructor() {
        this.backendApi = BackendApi.Instance;
    }

    async getImage(cameraId: number, filename: string, args?: any) {
        return this.backendApi.getApiResponse({
            endPoint: `/media/${cameraId}/${filename}`,
            queryStringParams: args
        });
    }

    async requestHDImage(mediaId: number): Promise<HDMediaRequest> {
        return this.backendApi.getApiResponse({
            endPoint: `/media/${mediaId}/hd`,
        });
    }

    async downloadMedia(caseId: number, media_ids: number[], filter_hd= false, include_hd_images_if_exists= false): Promise<Blob> {
        return this.backendApi.getApiResponse({
            endPoint: `/media/download`,
            method: "POST",
            data: {
                case_id: caseId,
                media_ids: media_ids,
                filter_hd: filter_hd,
                include_hd_images_if_exists: include_hd_images_if_exists
            }
        });
    }

    async markMediaAsLocked (mediaId: number) {
        return this.backendApi.getApiResponse({
            endPoint: `/media/mark-as-locked/${mediaId}`,
            method: "POST",
            data: {}
        });
    }

    async markMediaListAsLocked(media_ids: number[]) {
        return this.backendApi.getApiResponse({
            endPoint: `/media/mark-media-list-as-locked/`,
            method: "POST",
            data: {media_id_list: media_ids}
        });
    }

    async markMediaAsUnlocked (mediaId: number) {
        return this.backendApi.getApiResponse({
            endPoint: `/media/mark-as-unlocked/${mediaId}`,
            method: "POST",
            data: {}
        });
    }

    async markMediaListAsUnlocked(media_ids: number[]) {
        return this.backendApi.getApiResponse({
            endPoint: `/media/mark-media-list-as-unlocked/`,
            method: "POST",
            data: {media_id_list: media_ids}
        });
    }

    async getLockedMediaItems (locationId: number, captureDate: string) {
        return this.backendApi.getApiResponse({
            endPoint: `/media/get_locked_media_items/location/${locationId}/date/${captureDate}`,
            method: "GET"
        });
    }

    //async getMediaIsLockedValue (mediaId: number) {
    //    return this.backendApi.getApiResponse({
    //        endPoint: `/media/mark-as-locked/${mediaId}`,
    //        method: "POST",
    //        data: {}
    //    });
    //}
}